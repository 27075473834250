import { VerbType } from "./DataType";

export const verbList: VerbType[] = [
    {
        "present": "arise",
        "past": "arose",
        "participle": "arisen"
    },
    {
        "present": "be",
        "past": "was|were",
        "participle": "been"
    },
    {
        "present": "beat",
        "past": "beat",
        "participle": "beaten"
    },
    {
        "present": "become",
        "past": "became",
        "participle": "become"
    },
    {
        "present": "begin",
        "past": "began",
        "participle": "begun"
    },
    {
        "present": "bend",
        "past": "bent",
        "participle": "bent"
    },
    {
        "present": "bite",
        "past": "bit",
        "participle": "bitten"
    },
    {
        "present": "bleed",
        "past": "bled",
        "participle": "bled"
    },
    {
        "present": "blow",
        "past": "blew",
        "participle": "blown"
    },
    {
        "present": "break",
        "past": "broke",
        "participle": "broken"
    },
    {
        "present": "bring",
        "past": "brought",
        "participle": "brought"
    },
    {
        "present": "broadcast",
        "past": "broadcast",
        "participle": "broadcast"
    },
    {
        "present": "build",
        "past": "built",
        "participle": "built"
    },
    {
        "present": "burn",
        "past": "burnt|burned",
        "participle": "burnt|burned"
    },
    {
        "present": "burst",
        "past": "burst",
        "participle": "burst"
    },
    {
        "present": "buy",
        "past": "bought",
        "participle": "bought"
    },
    {
        "present": "catch",
        "past": "caught",
        "participle": "caught"
    },
    {
        "present": "choose",
        "past": "chose",
        "participle": "chosen"
    },
    {
        "present": "come",
        "past": "came",
        "participle": "come"
    },
    {
        "present": "cost",
        "past": "cost",
        "participle": "cost"
    },
    {
        "present": "creep",
        "past": "crept",
        "participle": "crept"
    },
    {
        "present": "cut",
        "past": "cut",
        "participle": "cut"
    },
    {
        "present": "deal",
        "past": "dealt",
        "participle": "dealt"
    },
    {
        "present": "dig",
        "past": "dug",
        "participle": "dug"
    },
    {
        "present": "do",
        "past": "did",
        "participle": "done"
    },
    {
        "present": "draw",
        "past": "drew",
        "participle": "drawn"
    },
    {
        "present": "dream",
        "past": "dreamt|dreamed",
        "participle": "dreamt|dreamed"
    },
    {
        "present": "drink",
        "past": "drank",
        "participle": "drunk"
    },
    {
        "present": "drive",
        "past": "drove",
        "participle": "driven"
    },
    {
        "present": "eat",
        "past": "ate",
        "participle": "eaten"
    },
    {
        "present": "fall",
        "past": "fell",
        "participle": "fallen"
    },
    {
        "present": "feed",
        "past": "fed",
        "participle": "fed"
    },
    {
        "present": "feel",
        "past": "felt",
        "participle": "felt"
    },
    {
        "present": "fight",
        "past": "fought",
        "participle": "fought"
    },
    {
        "present": "find",
        "past": "found",
        "participle": "found"
    },
    {
        "present": "fly",
        "past": "flew",
        "participle": "flown"
    },
    {
        "present": "forbid",
        "past": "forbade",
        "participle": "forbidden"
    },
    {
        "present": "forget",
        "past": "forgot",
        "participle": "forgotten"
    },
    {
        "present": "forgive",
        "past": "forgave",
        "participle": "forgiven"
    },
    {
        "present": "freeze",
        "past": "froze",
        "participle": "frozen"
    },
    {
        "present": "get",
        "past": "got",
        "participle": "got|gotten"
    },
    {
        "present": "give",
        "past": "gave",
        "participle": "given"
    },
    {
        "present": "go",
        "past": "went",
        "participle": "gone"
    },
    {
        "present": "grow",
        "past": "grew",
        "participle": "grown"
    },
    {
        "present": "hang",
        "past": "hung",
        "participle": "hung"
    },
    {
        "present": "have",
        "past": "had",
        "participle": "had"
    },
    {
        "present": "hear",
        "past": "heard",
        "participle": "heard"
    },
    {
        "present": "hide",
        "past": "hid",
        "participle": "hidden"
    },
    {
        "present": "hit",
        "past": "hit",
        "participle": "hit"
    },
    {
        "present": "hold",
        "past": "held",
        "participle": "held"
    },
    {
        "present": "hurt",
        "past": "hurt",
        "participle": "hurt"
    },
    {
        "present": "keep",
        "past": "kept",
        "participle": "kept"
    },
    {
        "present": "kneel",
        "past": "knelt|knealed",
        "participle": "knelt|knealed"
    },
    {
        "present": "know",
        "past": "knew",
        "participle": "known"
    },
    {
        "present": "lay",
        "past": "laid",
        "participle": "laid"
    },
    {
        "present": "lead",
        "past": "led",
        "participle": "led"
    },
    {
        "present": "lean",
        "past": "leant|leaned",
        "participle": "leant|leaned"
    },
    {
        "present": "learn",
        "past": "learnt|learned",
        "participle": "learnt|learned"
    },
    {
        "present": "leave",
        "past": "left",
        "participle": "left"
    },
    {
        "present": "lend",
        "past": "lent",
        "participle": "lent"
    },
    {
        "present": "let",
        "past": "let",
        "participle": "let"
    },
    {
        "present": "lie",
        "past": "lay",
        "participle": "lain"
    },
    {
        "present": "light",
        "past": "lit",
        "participle": "lit"
    },
    {
        "present": "lose",
        "past": "lost",
        "participle": "lost"
    },
    {
        "present": "make",
        "past": "made",
        "participle": "made"
    },
    {
        "present": "mean",
        "past": "meant",
        "participle": "meant"
    },
    {
        "present": "meet",
        "past": "met",
        "participle": "met"
    },
    {
        "present": "pay",
        "past": "paid",
        "participle": "paid"
    },
    {
        "present": "put",
        "past": "put",
        "participle": "put"
    },
    {
        "present": "read",
        "past": "read",
        "participle": "read"
    },
    {
        "present": "ride",
        "past": "rode",
        "participle": "ridden"
    },
    {
        "present": "ring",
        "past": "rang",
        "participle": "rung"
    },
    {
        "present": "rise",
        "past": "rose",
        "participle": "risen"
    },
    {
        "present": "run",
        "past": "ran",
        "participle": "run"
    },
    {
        "present": "say",
        "past": "said",
        "participle": "said"
    },
    {
        "present": "see",
        "past": "saw",
        "participle": "seen"
    },
    {
        "present": "sell",
        "past": "sold",
        "participle": "sold"
    },
    {
        "present": "send",
        "past": "sent",
        "participle": "sent"
    },
    {
        "present": "set",
        "past": "set",
        "participle": "set"
    },
    {
        "present": "sew",
        "past": "sewed",
        "participle": "sewn"
    },
    {
        "present": "shake",
        "past": "shook",
        "participle": "shaken"
    },
    {
        "present": "shine",
        "past": "shone",
        "participle": "shone"
    },
    {
        "present": "shoot",
        "past": "shot",
        "participle": "shot"
    },
    {
        "present": "show",
        "past": "showed",
        "participle": "shown"
    },
    {
        "present": "shrink",
        "past": "shrank",
        "participle": "shrunk"
    },
    {
        "present": "shut",
        "past": "shut",
        "participle": "shut"
    },
    {
        "present": "sing",
        "past": "sang",
        "participle": "sung"
    },
    {
        "present": "sink",
        "past": "sank",
        "participle": "sunk"
    },
    {
        "present": "sit",
        "past": "sat",
        "participle": "sat"
    },
    {
        "present": "sleep",
        "past": "slept",
        "participle": "slept"
    },
    {
        "present": "slide",
        "past": "slid",
        "participle": "slid"
    },
    {
        "present": "smell",
        "past": "smelt|smelled",
        "participle": "smelt|smelled"
    },
    {
        "present": "sow",
        "past": "sowed",
        "participle": "sown|sowed"
    },
    {
        "present": "speak",
        "past": "spoke",
        "participle": "spoken"
    },
    {
        "present": "spell",
        "past": "spelt|spelled",
        "participle": "spelt|spelled"
    },
    {
        "present": "spend",
        "past": "spent",
        "participle": "spent"
    },
    {
        "present": "spill",
        "past": "spilt|spilled",
        "participle": "spilt|spilled"
    },
    {
        "present": "spit",
        "past": "spat",
        "participle": "spat"
    },
    {
        "present": "split",
        "past": "split",
        "participle": "split"
    },
    {
        "present": "spoil",
        "past": "spoilt|spoiled",
        "participle": "spoilt|spoiled"
    },
    {
        "present": "spread",
        "past": "spread",
        "participle": "spread"
    },
    {
        "present": "spring",
        "past": "sprang",
        "participle": "sprung"
    },
    {
        "present": "stand",
        "past": "stood",
        "participle": "stood"
    },
    {
        "present": "steal",
        "past": "stole",
        "participle": "stolen"
    },
    {
        "present": "stick",
        "past": "stuck",
        "participle": "stuck"
    },
    {
        "present": "sting",
        "past": "stung",
        "participle": "stung"
    },
    {
        "present": "strike",
        "past": "struck",
        "participle": "struck"
    },
    {
        "present": "swear",
        "past": "swore",
        "participle": "sworn"
    },
    {
        "present": "sweep",
        "past": "swept",
        "participle": "swept"
    },
    {
        "present": "swell",
        "past": "swelled",
        "participle": "swollen"
    },
    {
        "present": "swim",
        "past": "swam",
        "participle": "swum"
    },
    {
        "present": "swing",
        "past": "swung",
        "participle": "swung"
    },
    {
        "present": "take",
        "past": "took",
        "participle": "taken"
    },
    {
        "present": "teach",
        "past": "taught",
        "participle": "taught"
    },
    {
        "present": "tear",
        "past": "tore",
        "participle": "torn"
    },
    {
        "present": "tell",
        "past": "told",
        "participle": "told"
    },
    {
        "present": "think",
        "past": "thought",
        "participle": "thought"
    },
    {
        "present": "throw",
        "past": "threw",
        "participle": "thrown"
    },
    {
        "present": "understand",
        "past": "understood",
        "participle": "understood"
    },
    {
        "present": "wake",
        "past": "woke",
        "participle": "woken"
    },
    {
        "present": "wear",
        "past": "wore",
        "participle": "worn"
    },
    {
        "present": "weep",
        "past": "wept",
        "participle": "wept"
    },
    {
        "present": "win",
        "past": "won",
        "participle": "won"
    },
    {
        "present": "write",
        "past": "wrote",
        "participle": "written"
    }
]
