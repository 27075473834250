import { Tooltip } from '@mui/material';
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type Props = {
    id: string,
    regexp: RegExp,
    tooltip: string,
    reset: boolean,
}

export default function Gap(props: Props) {

    const [value, setValue] = React.useState(localStorage.getItem(props.id) ?? '')
    const [isCorrect, setIsCorrect] = React.useState(props.regexp.test(value))
    const [showError, setShowError] = React.useState(value !== '')
    const [focus, setFocus] = React.useState(false)
    
    const id = React.useRef(props.id)

    const evaluate = React.useCallback((val: string) => {
        setShowError(val !== '' && !!val)
        setIsCorrect(props.regexp.test(val))
    }, [props.regexp])

    React.useEffect(() => {

    }, [focus])

    React.useEffect(() => {
        evaluate(value)
    }, [evaluate, value])

    React.useEffect(() => {
        id.current = props.id
        setValue(localStorage.getItem(props.id) ?? '')
    }, [props.id])

    React.useEffect(() => {
        localStorage.setItem(id.current, value)
    }, [value])

    React.useEffect(() => {
        if (props.reset) {
            setValue('')
        }
    }, [props.reset])

    return (<div className='GapContainer'>
        <input
            className={'Gap'}
            type='text'
            value={value}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            required={false}
            onChange={(event) => setValue(event.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            style={{
                backgroundColor: !focus && isCorrect ? 'yellowgreen' : 'lightgrey',
                borderBottomColor: !focus && isCorrect ? 'yellowgreen' : (!focus && showError ? 'red' : 'darkgrey')
            }}
        />
        <Tooltip 
            title={props.tooltip}
            placement='right'
            arrow
            className='Tooltip'>
            <HelpOutlineIcon className='Tooltip' />
        </Tooltip>
    </div>);
}

