
type BoxProps = {
    children: React.ReactNode
}

export default function TableContainerBox(props: BoxProps) {

    return <div className='TableContainerBox'>
        {props.children}
    </div>
}
