import React from 'react';
import './App.css';
import Actif from './Actif';
import { Card } from '@mui/material';

function App() {

  const [reset, setReset] = React.useState(false)

  React.useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset])

  return (
    <div className="App">
        <Card className='Content' variant='outlined'>
          <Actif
            reset={reset}
          />
        </Card>
    </div>
  );
}

export default App;
