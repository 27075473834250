
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { verbList } from './data/verbs';
import Gap from './Gap';
import './Styles.css';
import TableContainerBox from './TableContainerBox';

type Props = {
    reset: boolean,
}

export default function Actif(props: Props) {
    
    const headers = ['Present tense', 'Past tense', 'Past participle'].map((elem, index) =>
        <TableCell
            key={`header-${index}`}
            align='left' 
        >
            {elem}
        </TableCell>
    )
    
    const computeRegExp = (s: string) => s.split('|').reduce((prev: string[][], curr: string) => {
        if (prev.length === 0) {
            return [[curr]]
        } else {
            return prev.flatMap(elem => [[curr, ...elem], [...elem, curr], [curr], ...prev])
        }
    }, []).map(elem => elem.join('([\\s]*[/,][\\s]*)')).join('|')

    const rows = verbList.map((verb, index) => {

        const pastRegExp = new RegExp(`^[\\s]*(${computeRegExp(verb.past)})[\\s]*$`)
        const participleRegExp = new RegExp(`^[\\s]*(${computeRegExp(verb.participle)})[\\s]*$`)
        
        return <TableRow key={`row-${index}`}>
            <TableCell key={`${verb.present}-${index}-0`}>
                <div>{verb.present}</div>
            </TableCell>
            <TableCell key={`${verb.present}-${index}-1`}>
                <Gap
                    id={`${verb.present}-${index}-1`}
                    regexp={pastRegExp}
                    tooltip={verb.past.replace('|', '/')}
                    reset={props.reset}
                />
            </TableCell>
            <TableCell key={`${verb.present}-${index}-2`}>
                <Gap
                    id={`${verb.present}-${index}-2`}
                    regexp={participleRegExp}
                    tooltip={verb.participle.replace('|', '/')}
                    reset={props.reset}
                />
            </TableCell>
        </TableRow>
    })

    return (
        <TableContainer component={TableContainerBox}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headers}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
